@import '../../theme/shared.less';

.generic {
  transition: all @transitionDuration;
}

.reflex {
  transform: scaleX(-1);
}

:global {

  .ant-btn:hover {
    .anticon {
      fill: @icon-color-hover;
      stroke: @icon-color-hover;
    }

    &:disabled .anticon  {
      fill: @icon-color;
      stroke: @icon-color;
    }
  }

  .anticon {
    fill: @icon-color;
    stroke: @icon-color;
  }

  .ant-menu {
    .anticon {
      fill: @icon-color-menu;
      stroke: @icon-color-menu;
    }
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected:not(.ant-menu-submenu-active)>.ant-menu-submenu-title
   {
    .anticon {
      fill: @link-color-hover;
      stroke: @link-color-hover;
    }

    &:disabled .anticon  {
      fill: @icon-color-menu;
      stroke: @icon-color-menu;
    }
  }

  a span.anticon {
    fill: @link-color;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;