@import "theme/shared.less";

.menuToggler {
  width: @menu-side-width;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid @paddings-color;

  .btn {
    height: inherit;
    width: inherit;
  }

  &:hover :global(.ant-btn.ant-btn-text) {
    color: @primary-color;
    background-color: transparent;
  }
}

.tabs {
  width: @menu-side-width + 1px;
  margin-right: -1px;
  height: 100%;
  :global(.ant-menu-item) {
    position: relative;
    margin: 0;
    margin-top: 4px;
    padding-inline: 13px !important;
    width: 100%;
  }
  &:global(.ant-menu-vertical .ant-menu-submenu-title) {
    padding-inline: 9px;
    text-overflow: clip;
    background-color: transparent;
  }
  &:global(.ant-menu-vertical .ant-menu-title-content) {
    &:not(:has(.lastOfGroup)) {
      @import "./notch.less";
    }
    &:has(.lastOfGroup) {
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        background-color: @paddings-color;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  &:global(.ant-menu-item .ant-menu-submenu-title .ant-menu-title-content) {
    padding-inline: 9px;
    text-overflow: clip;
    background-color: transparent;
  }

  :global(.ant-menu-submenu) {
    position: relative;
  }
  :global(.ant-menu-item) {
    position: relative;
    padding-inline: 13px !important;
    width: 100%;
    &:hover :global(.anticon) {
      color: @primary-color;
      background-color: transparent;
    }
  }

  :global(.ant-menu-item-active::after) {
    content: "";
    width: 4px;
    height: 32px;
    background: @colorPrimary;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    display: flex;
    position: absolute;
    top: 2px;
    right: 0;
  }

  :global(.ant-menu-submenu-selected::after) {
    content: "";
    width: 4px;
    height: 32px;
    background: @colorPrimary;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    display: flex;
    position: absolute;
    top: 2px;
    right: 0;
  }

  &:global(
      .ant-menu:not(.ant-menu-horizontal)
        .ant-menu-item:not(.ant-menu-item-selected):hover
    ) {
    background-color: transparent;
  }
  &:global(.ant-menu .ant-menu-item-selected) {
    background-color: transparent;
  }

  &:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover) {
    background-color: transparent;
  }

  :global(.ant-menu-submenu-arrow) {
    display: none;
  }
}

.popup {
  max-width: 350px;

  :global(.ant-menu > .ant-menu-item) {
    line-height: 30px;
    height: 30px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;