.card {
  display: flex;
  align-items: center;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #DDD;
    margin-right: 8px;
  }

  .large-avatar {
    .avatar;
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;