@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid @table-color-border;
    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .tabs {
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;

      & :global(.ant-tabs-nav) {
        &::before {
          content: normal;
        }
        height: 48px;

        :global(.ant-tabs-ink-bar) {
          height: 4px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;