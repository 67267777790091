@import "theme/table.less";

.table {
  border: 1px solid white;
  padding: 0px;
  margin-top: 4px;
  background-color: white;
  color: rgba(0, 0, 0, 0.88);
  overflow-y: hidden;
  height: 194px;
}

.headerCell {
  font-size: 12px !important;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;