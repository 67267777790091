.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
    background-color: white;
    box-shadow: 0px 4px 8px 0px #00000029;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    width: 440px;

    p {
      margin: 8px 0px;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #999999;
    }

    h1 {
      margin: 0px 0px 64px 0px;
      font-size: 22px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
    }

    .input :global(.anticon) {
      fill: #999;
      stroke: #999;
      font-size: 16px;
    }

    .submit {
      margin-bottom: 16px;
    }
  }

  .logo {
    height: 233px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;