@import "theme/shared.less";

.tabs {
  height: 100%;

  :global {
    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

.divider {
  margin: 0;
  border-block-start: 1px solid @paddings-color;
}

.wrapper {
  padding: 16px 0;
  height: 100%;
}

.paramsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 16px;
}

.economicTotalSum {
  border: 1px solid @paddings-color;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;

  div:first-child {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #111927;
  }
  div:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.paramsTitleBlock {
  display: flex;
  justify-content: space-between;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;