@import "theme/shared.less";

@bg-color: @paddings-color;

.field {
  display: flex;
  column-gap: 8px;
  padding-right: 16px;

  :global {
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item-row {
      display: block !important;
    }
  }
}

.catalogBtnWrapper {
  padding: 8px;
  border-radius: 4px;
  background: @minor-bg;
  border: 1px solid @bg-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 16px 16px 0;
}

.selectedName {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.nodeParamsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.tehnologyWrapper {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 16px 8px 0;
  :global {
    .ant-form-item {
      margin: 12px 0;

      .ant-form-item-label > label, .ant-form-item-control, input {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}

.date {
  width: 100%;
}

.divider {
  margin: 0;
  border-block-start: 1px solid @paddings-color;
}

.coordParams {
  margin: 12px 0;
}

.nodeTabs {
  :global(.ant-tabs-content-holder) {
    max-height: calc(100vh - 400px);
  }
}

.fieldItem {
  margin: 12px 0;
}

.relatedItems {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;