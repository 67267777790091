@import './techLayoutSettings.less';

.param {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: @param-title-gap;

  &>h4 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: @title-line-height;
    margin: 0;
    flex: 1;
  }

  .input {
    max-width: 180px;
    flex: 0.5;

    &>:global(.ant-picker) {
      min-width: 180px;
    }

    &>:global(.ant-select) {
      min-width: 180px;
    }
  }
}

.params {
  display: flex;
  flex-direction: column;
  gap: @params-gap;
  margin: 0;
  padding: 0;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;