.tooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: rgba(77, 87, 97, 1);
  border-radius: 8px;
  padding: 8px 12px;
  min-width: 120px;
  max-width: 190px;
  pointer-events: none;
  z-index: 1000000;
  position: absolute;
}

.header {
  color: rgba(210, 214, 219, 1);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.valueRow {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.value {
  color: #fff;
  font-size: 10px;
}

.year {
  color: #fff;
  font-size: 10px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;