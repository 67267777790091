@import "theme/shared.less";

.emptyScreen {
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  height: 100%;
  display: flex;
}

.uploadIcon {
  width: 16px;
  height: 16px;
  color: white;
}

.dragger {
  width: 100%;
  height: 100%;
}

.notDataText {
  margin-top: 13px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(77, 87, 97, 1);
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;