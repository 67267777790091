@import "theme/shared.less";

.layout {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: relative;

  .points {
    position: absolute;
    left: 40px;
    z-index: 1;
  }

  .chart {
    flex: 1;
    z-index: 2;
    .analog {
      opacity: 0;
      z-index: 100;

      transition: opacity 0.2s linear;

      &.current {
        z-index: 200;
        opacity: 1;
        line {
          stroke-width: 3px;
        }
      }
    }

    .fitting, .rawFitting, .current{
      path {
        stroke-width: 3px;
      }
    }

    .fitting {
      stroke-dasharray: 10,5;
      stroke-width: 3px;
    }
    .rawFitting {
      stroke-dasharray: 2,2;
      stroke-width: 3px;
    }
  }


  .list {
    width: 250px;

    display: flex;
    flex-direction: column;
    border-left: 1px solid @paddings-color;
    z-index: 2;

    h4 {
      height: 48px;
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @paddings-color;
      color: #111927;
      padding-left: 8px;
      font-weight: 600;
    }

    .items {
      flex: 1;
    }

    .hidden{
      color: @icon-color-menu;
    }

    .update {
      display: flex;
      border-top: 1px solid @paddings-color;
      height: 64px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .metrics {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        padding: 0px 4px;
        background-color: @table-color-cell-lock;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;

        .label {
          color: @icon-color-menu;
        }

        .value {
          color: #111927;
          justify-self: end;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          max-width: 38px;
          margin-right: 2px;
        }
      }
    }
  }

  .checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &>:last-child{
      flex: 1;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;