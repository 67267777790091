

.selectors {
  display: flex;
  gap: 5px;
  align-items: center;

  .btns {
    margin-left: -37px;
    margin-bottom: -5px;
    margin-top: -3px;
    display: flex;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;