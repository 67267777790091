@import "theme/shared.less";

.legendMap {
  width: 275px;
  border-radius: 4px;
  border: 1px solid @table-color-head-lock;
  background: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: absolute;
  bottom: 50px;
  right: 10px;

  .legendFlex {
    display: flex;
    column-gap: 8px;
    align-items: center;
    height: 20px;
  }

  .horizontalLine {
    width: 24px;
    height: 2px;
    background-color: @colorPrimary;
  }

  .dottedLine {
    border-top: 2px dotted @colorPrimary;
    width: 24px;
    height: 0px;
  }

  .colorBlock {
    flex: 1;
    height: 8px;
  }

  .scaleLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }
}

.icon {
  stroke: none;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;