.afterButton {
  position: relative;
  button:global(.ant-btn.ant-btn-icon-only) {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;

    :global(.anticon) {
      font-size: 20px;
    }
  }

  &:hover {
    button:global(.ant-btn.ant-btn-icon-only) {
      opacity: 1;
    }
  }
}

.tooltipWrapper {
  width: 100%;

  input {
    font-size: inherit;
  }

  & > div {
    padding-left: 0px;
    font-size: inherit;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;