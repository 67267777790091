@import "theme/shared.less";
@import "theme/table.less";

.generic {
  margin: 8px 0px;
  border: 1px solid #eaecf0;
  background: #fff;
  overflow: auto;
  max-height: calc(100% - 80px);
  padding: 8px 16px;
  .tableWrap {
    width: 100%;
    height: 100%;
    h2 {
      font-weight: 500;
      margin: 0px;
      padding: 0px;
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 20px;
      padding-top: 8px;
    }
    .simpleTable {
      height: calc((@table-row-size * var(--line-count)) + @table-headerRow-height + @table-scroll-height);
    }
  }
}



@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;