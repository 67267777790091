@import "theme/shared.less";
@import "theme/table.less";

@disabledColor: rgba(0, 0, 0, 0.25);

.summaryRow {
  > div {
    background-color: #fef0c7;
    font-weight: bold;
  }
}

.tableRightSticky {
  position: sticky;
  right: 0;
}

.table {
  margin: 8px;
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid @table-color-border;
  height: 100%;
}

.fallBackBg {
  background-color: #ffbac1 !important;
}

.disabled {
  color: @disabledColor;
  transition: all 0.5s;

  :global(.ant-select-selection-item) {
    color: @disabledColor;
    cursor: not-allowed;
  }
}

.notSelectedWells {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
}

.importWellReservesLink {
  color: white;
}

.importWellReservesIcon {
  margin: 0 4px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;