@import "theme/shared.less";
@import "theme/table.less";

.tableWrap {
  margin-right: 8px;
  width: calc(100% - 8px);
  height: 100%;
  color: #111927;

  .row {
    cursor: pointer;
  }

  .selected-row {
    .row;
    > div {
      background-color: #84caff;
    }
  }

  .link {
    text-decoration: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #175cd3;
  }

  .noWells {
    width: 16px;
    height: 16px;
    stroke: none;
    margin-left: -4px;
  }

  .loader{
    margin-left: -4px;
  }

  :global(.ant-picker) {
    width: 80px;
  }


  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    :global{
      .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover{
        background: none;
      }
      .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
        background: none;
      }
    }
    .copy-button {
      color: #4D5761;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
    }
    .delete {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      width: 20px;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;