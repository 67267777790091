@import "theme/shared.less";
@header-height: 61px;

.wrap .tabs {
  :global(.ant-tabs-tab-btn) {
    z-index: 2;
  }
  :global(.ant-tabs-nav) {
    :global(.ant-tabs-nav-wrap) {
      overflow: visible;

      :global(.ant-tabs-ink-bar) {
        background-color: @table-color-secondary;
        border-radius: 4px;
        height: 32px;
        width: 32px !important;
        bottom: 10px;
        z-index: 1;
      }

      :global(.ant-tabs-tab:first-child) {
        margin-left: 6px;
      }
    }
  }
}

.wrap > .content {
  max-height: calc(100vh - @header-height - @header-height);
  overflow: hidden;
}

.wrap {
  position: relative;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;