&::after {
  content: "";
  width: 24px;
  height: 1px;
  display: block;
  background-color: @paddings-color;
  position: absolute;
  left: calc(50% - 12px);
  bottom: 0px;
}
  