@import "theme/shared.less";

.wells {
  height: 600px;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid @paddings-color;

  .input {
    margin-top: 8px;
    width: 100%;
  }

  .month-picker {
    box-sizing: border-box;
    padding: 16px;
    padding-bottom: 12px;
    border-top: 1px solid @paddings-color;
  }

  .year-picker {
    .month-picker;
    border-top: none;
    padding-top: 0;
  }

  .select {
    box-sizing: border-box;
    padding: 16px;
    padding-top: 0;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;