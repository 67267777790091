@import "theme/shared.less";
.empty {
  background-color: @paddings-color;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout {
  display: flex;
  flex-direction: column;
  background-color: @paddings-color;
  position: relative;
  height: 100%;

  .toolbar,
  .table,
  .map,
  .datePane {
    background-color: white;
  }

  .toolbar {
    position: absolute;
    top: 8px;
    left: 8px;

    .buttons {
      display: flex;
      gap: 4px;
    }
  }

  .mapNDate {
    display: flex;
    flex-direction: column;
    gap: 1px;
    flex: 1;
    .map {
      flex: 1;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}




@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;