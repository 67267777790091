@import "theme/shared.less";

.scalar {
  h2, h3, h4 {
    font-weight: 500;
    margin: 0px;
    padding: 0px;
  }

  h2 {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .subset {
    margin-bottom: 20px;

    h3 {
      line-height: 20px;
      font-size: 14px;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 16px;

      :global(.anticon) {
        font-size: 24px;
      }
    }

    .values {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      .value {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 8px;

        h4 {
          font-size: 12px;
        }

      }
    }
  }
}

.conditionally {
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 800px;
    align-items: flex-start;

    .autoCalc {
      margin-left: 11px;
      margin-right: -11px;
    }

  }
  .collapse {
    margin-top: 8px;
    .loader {
      text-align: center;
    }
    
    :global(.ant-collapse-content-box) {
      margin: -12px;
      margin-bottom: 0px;
    }
  }
  .simpleTable {
    padding-right: 2px;
    height: calc((@table-row-size * var(--line-count)) + @table-headerRow-height + @table-scroll-height);
  }
}

.inputUnitCostsContainer {
  padding-top: 8px;
}

.unitCostsCheckbox {
  margin-bottom: 8px;
}

.inputUnitCostsTableWrapper {
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  overflow: hidden;
  font-size: 14px;
}

.fillNetVectorBtn {
  margin-top: 8px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;