@import "theme/shared.less";

.margin {
  margin: @card-margin @card-margin @card-margin 0px;
}

.card {
  background-color: white;
  border-radius: 4px;
  border: solid 1px @paddings-color;
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
  }

  .legend {
    display: flex;
    gap: 16px;

    .legendItem {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .title {
    margin: 0px;
  }

  > .body {
    margin: 16px 16px 16px 16px;
    flex: 1;
    display: flex;

    > * {
      flex: 1;
    }
  }
}

.section {
  padding: 16px 16px 0px 16px;

  .title {
    /*
    должно уйти в типографию
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #101828;
    */
    margin: 0px;
  }

  .subtitle {
    /*
    должно уйти в типографию
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: rgba(152, 162, 179, 1);
    */
    margin-top: 4px;
  }

  .body {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    /*
    должно уйти в типографию
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    */
    line-height: 16px;
  }
}

.stick {
  background-color: rgba(249, 250, 251, 0.7);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  padding: 5px 10px 3px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  pointer-events: none;

  .stickHeader {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .body {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: 100%;
  }
}

.stickTransparent {
  background-color: transparent;
  border: none;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;