@import "theme/shared.less";

.card {
  border: 1px solid @paddings-color;
  border-radius: 4px;
  background-color: #F9FAFBB2;
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .icon {
      margin-right: 4px;
    }

    .title {
      display: flex;
      text-transform: uppercase;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;