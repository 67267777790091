.tableHeader > div {
  background-color: #f7f7f7;
  font-weight: 600;
  font-size: 14px;
  text-wrap: wrap;
}
.tableRowPrimary > div {
  background-color: #d1e9ff;
  font-weight: 600;
}
.tableRowSecondary > div {
  background-color: #eff8ff;
}
.tableRowPlain > div {
  background-color: white;
}
.tableCellAlignRight {
  justify-content: end;
  text-align: end;
}
.tableCellLock {
  background-color: #e5e7eb;
}
.simpleTable {
  padding: 16px;
  height: calc((30px * var(--line-count)) + 40px + 30px);
}
.static {
  margin-top: 8px;
}
.static .static-container {
  padding: 16px;
  height: 400px;
  width: 100%;
}
.static .alert {
  margin: 16px;
}
.static .link {
  height: 0;
  padding: 0;
}
.additional {
  margin: 16px;
}
.additional .ecy {
  margin-bottom: 8px;
}
.additional .additional-inputs {
  display: flex;
  align-items: center;
}
.additional .additional-inputs .cost-selector {
  width: 484px;
}
.additional .additional-inputs .onns {
  margin-left: 8px;
}
.additional .additional-inputs .switch {
  margin-left: 12px;
}
.rank {
  border-radius: 100px;
  min-width: 35px;
  text-align: center;
}
