@import "theme/shared.less";

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    background-color: white;
    margin: 1px 0px 1px 0px;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @table-color-border;
    margin-bottom: 4px;
    padding: 0 16px;
    gap: 8px;
    .legend {
      display: flex;
      gap: 12px;
    }
    .legendItem {
      display: flex;
      align-items: center;
      gap: 4px;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }

  .chart {
    flex: 1;
    background-color: white;
  }

  &:first-child {
    border-bottom: 1px solid @table-color-border;
  }
  &:nth-child(2) {
    border-top: 1px solid @table-color-border;
    border-bottom: 1px solid @table-color-border;
  }
  &:nth-child(3) {
    border-top: 1px solid @table-color-border;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;