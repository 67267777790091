.modal {
  display: grid;
  grid-template-columns: 0.6fr 0.7fr 1fr 1fr;
  gap: 8px;

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  .span2 {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .spanInput {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .span4 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;