@import '../../theme/shared.less';

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - @header-height);
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-shadow: 0 1px 2px var(--ant-color-bg-container);
  color: var(--ant-color-primary);

  .close {
    opacity: 0.2;
    position: fixed;
    top: 100px;
    right: 100px;
    transform: scale(5);

    &:hover {
      opacity: 1;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;