@import "theme/shared.less";

.wrap {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1;

  .pane {
    background-color: #ffffff;
    border-bottom: 1px solid @paddings-color;
    height: 47px;
    margin-bottom: 1px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pane-buttons {
      display: flex;
      align-items: center;
      gap: 24px;
      font-size: calc(24px / 1.1428);
    }

    .pane-buttons-search {
      display: flex;
    }

    .grey-button {
      border: none;
      font-size: calc(24px / 1.1428);

      &:hover {
        z-index: 1;
      }

      :global(.anticon) {
        display: block;
        font-size: calc(24px / 1.1428);
      }
    }
  }

  .table {
    flex: 1;
    background-color: @minor-bg;
    margin: 8px;
    overflow: auto;
    border-radius: 8px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;