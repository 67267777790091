.item {
  display: flex;
  padding: 4px;
  align-items: center;
}

.select {
  cursor: pointer;
}

.selected {
  color: #84caff;
}

.checkbox {
  overflow: hidden;

  span:first-of-type {
    overflow: visible;
  }

  span:last-of-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;