@import "theme/table.less";

.table {
  flex-grow: 1;
  margin: 8px;

  .row {
    cursor: pointer;

    .delete-cell {
      position: sticky;
      right: 0;
    }
  }

  .selected-row {
    .row;
    > div {
      background-color: #84caff;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;