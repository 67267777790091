@import "theme/shared.less";

.container {
  margin: 0px;
  padding: 10px 8px;
  list-style-type: none;

  >li {
    display: flex;
    justify-content: flex-start;
    width: 300px;
    padding-bottom: 16px;
    gap: 10px;

    .label {
      color: #4D5761;
      min-width: 160px;
    }

    .value {
      flex: 1;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;