@import "theme/table.less";

@warning: rgba(247, 232, 234, 1);

.rowBorderNone > div {
  border-right: none !important;
  border-left: none !important;
}

.tableFirstCell {
  padding: 2px 8px !important;
  font-size: 12px;
  div, input {
    font-size: 12px;
  }
}

.tableCell {
  font-size: 12px;
  div {
    font-size: 12px !important;
  }
  input, :global(.ant-picker) {
    width: 100%;
    font-size: 12px !important;
  }
}

.inputCustomWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  & > div {
    white-space: nowrap;
  }
}

.subFrameTitle {
  background: white;
  width: 100%;
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  margin-bottom: 12px;
  border: 1px solid #e5e7eb;

  .date {
    max-width: 165px;
    height: 32px;
  }

  .inputMax {
    max-width: 330px;
    height: 32px;
  }

  input {
    font-size: 12px;
  }

  :global(.ant-divider-vertical) {
    height: 2.3em;
  }
}

.table {
  height: calc(100% - 32px);
}

.columnWarning {
  background-color: @warning !important;
}


.warning {
  background-color: #ffbac1;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;