@import "theme/shared.less";

.container {
  height: 100%;
  background-color: white;
  min-width: 350px;

  border: 1px solid @paddings-color;
  border-right: none;
  border-bottom: 1px solid @paddings-color;
  box-sizing: border-box;

  .scroller {
    height: calc(100% - 50px);
  }

  .title {
    font-weight: 600;
    padding: 16px;
    border-bottom: 1px solid @paddings-color;
    font-size: 10px;
    text-transform: uppercase;
    height: 48px;
    margin: 0px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;