@import "./techLayoutSettings.less";
@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: column;
  min-width: 1060px;
  max-height: 100vh;

  .correction {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .inversion {
    :global {
      .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
        &:hover {
          background-color: #57aeff;
          border-color: #57aeff;
        }
        &::before {
          background-color: #fff;
        }
      }
      .ant-radio-button-wrapper-checked {
        background: #fff;
        border-color: @borderColor;
        &:hover {
          background: #fff;
          color: #2e90fa;
          border-color: @borderColor;
        }
      }
    }
  }

  .results {
    flex: 1;
    min-height: 200px;
    max-height: @chart-height;

    @media (max-height: 574px) {
      min-height: 750px;
    }
  }

  .empty {
    height: 350px;
    border: 1px solid @paddings-color;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;