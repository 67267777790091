@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: row;
  gap: 1px;
  padding: 1px;
  background-color: @paddings-color;
  min-height: 160px;
  max-height: 45vh;
  overflow: auto;

  @media (max-height: 574px) {
    min-height: 344px;
  }

  >* {
    flex: 1;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;