.wrap {
  >:global(div.ant-modal-content) {
    :global {
      padding: 0px;
      border-radius: 3px;
      border: solid 1px #DDD;

      .ant-modal-header {
        background-color: #EEE;
        .ant-modal-title {
          color: #111927;
          font-size: 12px;
          font-weight: 600;
          line-height: 32px;
          >div {
            padding-left: 16px;
          }
        }

        margin: 0px;
        border-bottom: 1px solid #DDD;
      }

      .ant-modal-footer {
        border-top: 1px solid #DDD;
        padding: 16px;
        margin: 0px;
      }

      .ant-modal-close {
        right: 0;
        top: 0;
      }

      .ant-modal-body {
        padding: 16px;
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;