@import "theme/shared.less";

.container {
  background-color: white;
  width: 200px;
  border: solid 1px @paddings-color;
  border-radius: 8px;
  padding: 12px 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 0;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .divider {
      margin: 16px 0;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;