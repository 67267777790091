@import "theme/shared.less";

.tableHeader {
  > div {
    background-color: #f7f7f7;
    font-weight: 600;
    font-size: 14px;
    text-wrap: wrap;
  }
}

.tableRowPrimary {
  > div {
    background-color: @table-color-primary;
    font-weight: 600;
  }
}

.tableRowSecondary {
  > div {
    background-color: @table-color-secondary;
  }
}

.tableRowPlain {
  > div {
    background-color: white;
  }
}

.tableCellAlignRight {
  justify-content: end;
  text-align: end;
}

.tableCellLock {
  background-color: @table-color-head-lock;
}
