.layout {
  display: flex;
  gap: 8px;
  padding-left: 8px;
  height: 43px;
  font-size: 24px;
  align-items: center;

  .grid {
    flex: 1;
    align-self: flex-end;
    overflow-x: auto;
    width: 300px;

    .svg {
      height: 26px;
      width: calc(100% - 163px - 24px - 24px);

      text {
        font-size: 10px;
        pointer-events: none;
        user-select:none;
      }

      line {
        stroke: black;
        pointer-events: none;
      }

      .value {
        line {
          stroke-width: 4px;
          stroke: #017EF2;
        }
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;