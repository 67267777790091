@import "theme/table.less";
@import "theme/shared.less";

@navbar-height: 50px;

.importBtn {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 12px;
}

.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  z-index: 1000;
  max-width: calc(100% - 26px);

  :global {
    .ant-menu {
      width: 100%;
    }
    .ant-menu-title-content {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.remove {
  position: sticky;
  right: 0;
}

.empty {
  background: @minor-bg !important;
  width: 100%;
  height: 100%;
}

.tableCellInput {
  div {
    padding: 0;
    font-size: 12px;
  }
  :global(input) {
    font-size: 12px;
    padding: 0;
  }
}

.tableCellAlignRight > div {
  font-size: 12px;
  padding: 0;
  justify-content: end;
  text-align: end;

  div {
    text-align: end;
    justify-content: end;
  }
}

.tableCellAlignLeft {
  :global(.ant-select) > :global(.ant-select-selector) {
    padding: 0;
    font-size: 12px;
  }
}

.tableWrap {
  height: 100%;
  overflow-x: hidden;
  margin: 55px 10px 0 0;
}

.tableWrap > div {
  max-height: calc(100% - @navbar-height);
}

.disabled {
  color: rgba(0, 0, 0, 0.5);
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;