@import "theme/shared.less";

.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: 100%;

  > .header,
  .breadcrumb {
    background-color: white;
    padding: 0 16px 0 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 48px;
      margin: 0;
      flex: 1;
    }
  }

  .content {
    background-color: @minor-bg;
    flex: 1;
    padding: 8px;
    max-height: calc(100vh - @header-height - @header-height);
    box-sizing: border-box;
    overflow-y: auto;

    & > * {
      max-height: 100%;
      box-sizing: border-box;
      border-color: @paddings-color;
    }
  }

  .tabs {
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    justify-content: flex-end;

    & :global(.ant-tabs-nav) {
      &::before {
        content: normal;
      }
      height: 48px;

      :global(.ant-tabs-ink-bar) {
        height: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
}

.menu {
  max-height: 80vh;
  overflow: auto;
  max-width: 400px;
}

:global(.ant-tabs-nav-operations) {
  display: none !important;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;