.settingsContainer {
  display: flex;
  flex-direction: column;

  .settingsToolbar {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 6px;
  }

  .settingsItemRow {
    display: grid;
    grid-template-columns: auto 32px;

    .settingsItem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h3 {
        width: 100%;
      }

      .settingsSelect {
        min-width: 300px;
      }
    }

    .warnIconWrapper {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: red;
    }
  }
}

.recalculateErrorContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .link {
    padding: 0;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;