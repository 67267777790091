@import "theme/shared.less";

.item {
  @import "./item.less";

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 6px 2px 32px;

  &.selected {
    background-color: @table-color-secondary;
  }

  .label {
    flex: 1;
  }

  .remove {
    background: none;
    width: 20px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;