@import "theme/shared.less";
@import "../InfrastructureMap.module.less";

.lineHover:hover {
  stroke-width: 6px;
}

.newLine {
  stroke: @primary-color;
  pointer-events: none;
}

.circleNewLine {
  fill: @primary-color;
  stroke-width: 2;
  stroke: white;
  pointer-events: none;
}

.pipeDisabled {
  pointer-events: none;
  line {
    stroke: rgba(77, 87, 97, 0.2);
  }
}

.pipeSelected {
  stroke: @primary-color;
}

.pipeOil {
  stroke: #dc6803;
}

.pipeWater {
  stroke: #22ccee;
}

.arrowIcon {
  fill: @icon-color-menu;
  stroke: white;
}

.isDisabled {
  pointer-events: none;
}

.squareIcon {
  width: 9.34px;
  height: 9.34px;
  fill: @icon-color-menu;
  stroke: white;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;