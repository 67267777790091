@import 'theme/shared.less';

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;

  .menu {
    width: @menu-min-width - @menu-side-width;
    flex: 1;
    overflow: auto;

    :global(.ant-menu-submenu-title) {
      >:global(.anticon>.ant-menu-item-icon) {
        font-size: 16px;
      }
      >:global(.anticon):first-child {
        margin-right: -2px;
      }

      .folder {
        order: -1;
        margin-right: 8px;
        transform: rotate(-90deg);
        transition: transform 0.2s linear;
        &.open {
          transform: rotate(0deg);;
        }
      }
    }


    :global {
      .ant-menu.ant-menu-sub.ant-menu-inline {
        background-color: initial;
      }
      .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
      .ant-menu-submenu>.ant-menu-submenu-title,
      .ant-menu-item {
        border-radius: 0px;
        margin: 0px;
        height: 32px;
        line-height: 32px;
        width: 100%;
      }

      .ant-menu-item-selected {
        background-color: @table-color-secondary;
        color: black;
      }
    }
  }

  .no-data {
    width: @menu-min-width - @menu-side-width;
    background-color: @minor-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;

    >* {
      max-width: 214px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #4D5761;
      padding: 0px;
      margin: 0px;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;