@import "theme/shared.less";

@bg-color: @paddings-color;

.field {
  display: flex;
  column-gap: 8px;

  :global {
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item-row {
      display: block !important;
    }
  }
}

.catalogBtnWrapper {
  padding: 8px;
  border-radius: 4px;
  background: @minor-bg;
  border: 1px solid @bg-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 16px;
}

.selectedName {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.tehnologyWrapper {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 0;
  :global {
    .ant-form-item {
      margin: 12px 0;

      .ant-form-item-label > label,
      .ant-form-item-control,
      input {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .ant-input-wrapper {
        display: flex;
        column-gap: 4px;

        input {
          border-radius: 4px;
        }

        .ant-input-group-addon {
          display: flex;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          justify-content: center;
        }
      }
    }
  }

  .divider {
    margin: 0;
    border-block-start: 1px solid @paddings-color;
  }

  .date {
    width: 100%;
  }

  .paramsWrap {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 12px;
  }
}

.pipeTabs {
  :global(.ant-tabs-content-holder) {
    max-height: calc(100vh - 400px);
  }
}

.lengthWrap {
  div:last-child {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;

    button {
      height: 28px;
    }
  }
}

.input {
  font-size: 12px;
  width: 100%;
}

.pipelengthResetBtnTooltip {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;