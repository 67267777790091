@import "theme/shared.less";
@import "theme/table.less";

.warningMines {
  padding: 0 7.5px;
}

.widget {
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid @table-color-border;
  height: 400px;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
}

.buttonsWrapper {
  width: max-content;
  display: flex;
  column-gap: 10px;
  margin-left: auto;
}

.warningBlock {
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background: #fef0c7;
  width: 100%;

  :global {
    .ant-card-body {
      padding: 0;
      display: flex;
      column-gap: 4px;

      &::before {
        content: none;
      }
    }
  }
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;