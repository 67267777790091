@import "theme/shared.less";

.content {
  margin-top: 8px;
  background-color: white;
  border-top: 1px solid @table-color-border;
  flex: 1;
  padding: 8px;
  max-height: calc(100vh - @header-height - @header-height);
  box-sizing: border-box;

  & > * {
    max-height: 100%;
    box-sizing: border-box;
    border-radius: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: @paddings-color;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;