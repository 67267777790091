@import "theme/shared.less";
@import "theme/table.less";

.body {
  background-color: white;
  padding: 0 0 15px 8px;
}

.loader {
  display: flex;
  gap: 8px;
  margin-left: 8px;
  margin-top: -10px;
}

.rank {
  border-radius: 100px;
  min-width: 35px;
  text-align: center;
  font-weight: 100;
}

.tableCell {
  justify-content: right;
}

.arrowButtonsBlock {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;