@import "theme/table.less";
@import "theme/shared.less";

.simpleTable {
  padding: 16px;
  height: calc((@table-row-size * var(--line-count)) + @table-headerRow-height + @table-scroll-height);
}

.static {
  margin-top: 8px;

  .static-container {
    padding: 16px;
    height: 400px;
    width: 100%;
  }

  .alert {
    margin: 16px;
  }

  .link {
    height: 0;
    padding: 0;
  }
}

.additional {
  margin: 16px;

  .ecy {
    margin-bottom: 8px;
  }

  .additional-inputs {
    display: flex;
    align-items: center;

    .cost-selector {
      width: 484px;
    }

    .onns {
      margin-left: 8px;
    }

    .switch {
      margin-left: 12px;
    }
  }
}

.rank {
  border-radius: 100px;
  min-width: 35px;
  text-align: center;
}
