.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 8px;
}
.list {
  display: flex;
  gap: 4px;
  border: 1px solid @borderColor;
  border-radius: 4px;
  padding: 3px;
}

.tag {
  background: #0000000f;
  line-height: 22px;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 3px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;