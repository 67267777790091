@import "theme/shared.less";

@bg-color: @paddings-color;
@delete-btn-color: #ad211a;

.wrapper {
  :global(.ant-btn-dangerous) {
    color: @delete-btn-color;
    border-color: @delete-btn-color;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

.title {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #6c737f;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 8px;
  height: calc(100vh - 300px);
  overflow: scroll;

  & > div {
    padding: 8px;
    border: 1px solid @bg-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #175cd3;
    display: flex;
    column-gap: 4px;
    justify-content: space-between;
    border-radius: 4px;
    background: @bg-color;
    cursor: pointer;
  }
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;