.alerts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}


.importResourceModalWrap .importResourceModalTitle {
  margin: 5px 0 20px;
  font-weight: 600;
}

.footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  margin: 16px -16px -16px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;