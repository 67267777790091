@import "theme/shared.less";

.loader {
  height: 100%;

  > :global(.ant-spin-container) {
    height: 100%;
    .layout {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      height: 100%;
      border-left: 1px solid @paddings-color;
      border-right: 1px solid @paddings-color;

      .scene {
        flex: 1;
        background-color: white;
        @import "./dasharray.less";
      }
    }
  }
}

.empty {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;