.tooltip {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  div:first-child {
    width: 164px;
  }
  div:last-child {
    width: 125px;
  }
}

.catalogWrap {
  overflow: auto;
  height: calc(100% - 80px);
}

.categoryList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
}

.pipeCard {
  width: 100%;
}

.pipeParamsWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;