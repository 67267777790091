@import "theme/shared.less";


.cardParams {
  h2 {
    width: 100%;
  }

  .blockTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.gridFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  :global(.ant-picker) {
    width: 100%;
  }
}

.detailCardParent {
  flex-direction: column;
  row-gap: 8px;
}

.margin {
  margin: @card-margin 0 @card-margin 0;
}

.detailCardWrapper {
  padding: 8px 12px;
  background: fade(@minor-bg, 70%);
  border: 1px solid @paddings-color;
  border-radius: 4px;
  max-height: 56px;
  flex: none !important;

  .detailTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 4px;

    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .detailContent {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.projectCheckbox {
  display: flex;
  column-gap: 12px;
}


.fullWidth {
  width: 100%;
}

.waysTitle {
  margin-bottom: 20px;
}

.ways {
  margin-bottom: 24px;
}

.versionFem {
  margin: 8px 0;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;