@import "theme/shared.less";
@import "theme/table.less";

.oilDiffCell {
  &Positive {
    background-color: #bfec90 !important;
  }

  &Negative {
    background-color: #fba3a3 !important;
  }
}

.table {
  margin: 8px;
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid @table-color-border;
  height: 100%;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;