@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: relative;
  flex: 1;

  :global(.FullScreen) {
    display: flex;
    flex-direction: column;
  }

  .titleContainer {
    background-color: white;
    display: flex;
    font-family: Inter;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    gap: 24px;
    height: 31px;
    border-bottom: 1px solid @paddings-color;
    .header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0;
      .title {
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .wrap {
    padding: 0 16px;
    background-color: white;
    display: flex;
    flex: 1;
  }

  .chart {
    display: flex;
    flex: 1;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1;

  .big {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .pane {
    background-color: #ffffff;
    border-bottom: 1px solid @paddings-color;
    height: 47px;
    margin-bottom: 1px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pane-buttons {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .pane-buttons-search {
      display: flex;
    }

    .grey-button {
      border: none;
      font-size: calc(24px / 1.1428);

      &:hover {
        z-index: 1;
      }

      :global(.anticon) {
        display: block;
      }
    }
  }

  .body {
    flex: 1;
    background-color: @minor-bg;
    overflow: auto;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 390px 1fr;
    column-gap: 8px;
    row-gap: 8px;

    .card {
      min-height: 395px;
      .cardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        flex: 1;

        .inLine {
          display: flex;
          gap: 8px;
        }
        .big {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        .pieCenter {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 100%;

          > span {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin: auto;
          }
        }
      }
    }

    .payBack {
      .section {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        .stick {
          .big {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
          }
        }
      }
    }

    .rightDown {
      .cardBody {
        flex: 1;
      }
    }
  }
}

.chartStick {
  position: absolute;
  z-index: 1;
  margin: 10px;
}

.leftDown {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  .bordered {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(229, 231, 235, 1);
    border-radius: 10px;
    padding: 3px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;