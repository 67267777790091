@import "theme/shared.less";
@import "theme/table.less";

.root {
  display: flex;
  height: 100%;

  .view {
    flex: 1;

    padding-left: 5px;

    display: flex;
    flex-direction: column;
    gap: 5px;

    .chart {
      height: 100%;
      display: flex;
      flex: 1;
      position: relative;
      border: 1px solid @paddings-color;
    }

    & > *:nth-child(2) {
      flex: 1;
    }

    .table {
      height: 100%;
      .tableHeader {
        & > * {
          font-size: 12px !important;
        }
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;