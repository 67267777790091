@import "theme/shared.less";

.frame {
  background-color: white;
  min-width: 350px;
  max-height: 314px;
  overflow-y: auto;
  padding-bottom: 5px;

  @media (max-height: 574px) {
    max-height: 340px;
  }


  .title {
    padding: 10px 16px 8px 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 32px;

    .icon {
      font-size: 16px;
    }

    .warn {
      font-size: 16px;
      color: #faad14;
    }

    h3 {
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      flex: 1;
    }
      .metrics {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(2, 20px 1fr);
        justify-content: space-between;
        padding: 0px 4px;
        background-color: @table-color-cell-lock;
        border-radius: 4px;
        width: 126px;
        max-height: 32px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;

        .label {
          color: @icon-color-menu;

          &:nth-child(4n+3) {
            border-left: 1px solid white;
            padding-left: 2px;
          }
        }

        .value {
          color: #111927;
          justify-self: end;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          max-width: 38px;
          margin-right: 2px;
        }
      }
  }

  .content {
    padding: 0 16px 16px 16px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;