@import "theme/table.less";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableContainer {
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 5px 10px;

  .invalidCell {
    background-color: pink;
  }
}

.tableSupHeader {
  display: inline-flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.loader {
  height: 100%;

  > :global(div.ant-spin-container) {
    height: 100%;
  }
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;