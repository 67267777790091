.title {
  color: rgba(210, 214, 219, 1);
  font-size: 10px;
  font-family: Inter;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  letter-spacing: 0em;
  text-align: left;
  max-width: inherit;
}

.valueRow {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.value {
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;