@import "theme/shared.less";
@import "theme/table.less";

.widget {
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid @table-color-border;
  height: 50vh;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}

.previewText {
  margin: 16px 0 12px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;

  .switchBlock label {
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    padding: 5px 10px;
    height: 28px;
  }
}

.footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  margin: 16px -16px -16px;
}

.loader {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachIcon {
  width: 16px;
  height: 16px;
}

.uploadButtons {
  display: flex;
  column-gap: 8px;
}

.template {
  margin: 16px 0 8px;
  .templateTitle {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
  }

  .templateText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
  }
}

.templateTable {
  :global {
    .ant-table-cell {
      padding: 5px !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.templateTableWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.warningBlock {
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background: #fef0c7;
  width: 100%;

  :global {
    .ant-card-body {
      padding: 0;
      display: flex;
      column-gap: 4px;

      &::before {
        content: none;
      }
    }
  }
}

.isIncomplete > div:nth-child(n + 1) {
  background: #f7e8ea;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;