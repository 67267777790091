@import 'theme/shared.less';

.title {
  background-color: white;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  line-height: 48px;
  height: 48px;
  padding: 0px 16px;
  margin: 0px 1px 0px 0px;
  align-items: center;

  p {
    margin: 0px;
    text-transform: uppercase;
  }

  &>:first-child {
    flex: 1;
  }

  :global(.ant-btn) {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover {
      z-index: 1;
    }

    :global(.anticon) {
      display: block;
      font-size: 18px;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;