@import "theme/shared.less";

.container {
  overflow: hidden;
  background-color: #fff;
}

.title {
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid @table-color-border;
  box-sizing: border-box;
}

.section-title {
  padding: 16px;
  padding-bottom: 0;
  font-size: 13px;
  font-weight: 600;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;