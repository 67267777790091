@import "theme/shared.less";

.form {
  flex: 1;
  width: calc(50vw - @menu-min-width / 2 + 50px);

  .tabs {
    border-radius: 4px;
    letter-spacing: 0.6px;

    .tab {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .nameInput {
        margin-left: 51px;
      }

      .select {
        width: 400px;
      }

      .tabText {
        font-size: 10px;
        font-weight: 600;
      }
    }

    :global {
      .ant-tabs .ant-tabs-tab .anticon {
        margin-right: 0px;
      }

      .ant-tabs-ink-bar {
        height: 4px !important;
        border-radius: 10px 10px 0 0;
      }
    }

  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;