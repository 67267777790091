@import "theme/shared.less";
@import "../../../techLayoutSettings.less";

.items {
  margin: 0px;
  padding: 0px;
  list-style: none;
  background-color: white;
  border-left: @paddings-color solid 1px;
  overflow-y: scroll;
  min-height: 200px;
  max-height: @chart-height;

  @media (max-height: 574px) {
    max-height: 100%;
  }

  .title {
    display: flex;
    .icon {
      color: black;
      width: 8px;
      margin-left: 6px;
      margin-right: 6px;
      transition: all 0.1s ease-in;
      &.collapsed {
        transform: rotate(-90deg);
      }
    }
  }

  li {
    button {
      width: 100%;
      text-align: left;
      justify-content: start;
    }

    ol.channels {
      list-style: none;
      margin: 0;
      padding: 0;

      &.hiddenList {
        >li {
          height: 0;
        }
      }

      >li {
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-left: 10px;

        .colorPicker {
          border: none;
          background: none;

          :global(.ant-color-picker-color-block) {
            background: none;
            :global(.ant-color-picker-color-block-inner) {
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;