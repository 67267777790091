.field {
  display: grid;
  grid-template-columns: 35% 1fr;
}

.card {
  max-width: 750px;
}

.bodyCard {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.menuItem {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.menuItemDate {
  background: #f3f4f6;
  font-size: 11px;
  font-weight: 600;
  padding: 0 7px;
  border-radius: 30px;
  line-height: 22px;
}

.fieldTitle {
  color: rgba(77, 87, 97, 1);
}

.fieldValue {
  color: rgba(17, 25, 39, 1)
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;