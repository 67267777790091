@import "theme/shared.less";

div.card {
  margin: 8px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  background: #fff;
  padding: 20px 16px;
  overflow-y: auto;
}

div.overflow {
  overflow: auto;
}

.distrust {
  display: flex;
  flex-direction: column;
  gap: 5px;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;
  background-color: @minor-bg;

  .breadcrumb {
    border-bottom: 1px solid @paddings-color;
    padding: 0 16px;
  }

  .pane {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    border-bottom: 1px solid @paddings-color;
    height: 47px;
    margin-bottom: 1px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;

    .pane-buttons {
      display: flex;
      gap: 8px;
    }

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      .tooltip {
        width: 500px;
        padding: 20px;
      }
      .tooltipTab {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .body {
    display: flex;
    padding: 8px;
    flex: 1;
    flex-direction: column;

    .loader {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
    }
  }

  .table {
    flex: 1;
    background-color: @minor-bg;
    overflow: auto;
    border-radius: 8px;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;