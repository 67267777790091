@import "theme/shared.less";

.container {
  width: 383px;
  background-color: #FFF;
  height: 100%;
  border-left: 1px solid @paddings-color;
  overflow: auto;

  .header {
    padding: 16px;
    border-bottom: 1px solid @paddings-color;

    .title {
      text-transform: uppercase;
    }
  }

  .body {
    padding-inline: 16px;

    .link-button {
      font-weight: 600;
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }

  .license-region {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;

    .license-region-deposit {
      color: #4D5761;
    }
  }

  .creator-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  .card-container {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    color: #566481;

    .margin {
      margin-right: 8px;
    }

    .card-text {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .user-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid @paddings-color;
  }

  .loader {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}


@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;