@import "theme/shared.less";

.donut-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid @table-color-border;
  flex: 0 0 360px;

  .pieCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    > span {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin: auto;
    }
    .big {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
}

.card-section-body-item-title span {
  color: rgba(71, 84, 103, 1);
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;