@import "./shared.less";

@font-face {
  font-family: 'Inter';
  src: url("../../public/fonts/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url("../../public/fonts/Inter-SemiBold.ttf");
  font-style: normal;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  background-color: @paddings-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
&::-webkit-scrollbar-thumb {
  background-color: rgba(229, 231, 235, 1);
  border-radius: 10px;
  border: 2px solid white;
}

&::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}

&::-webkit-scrollbar-corner {
  border-radius: 10px;
}

&::-webkit-scrollbar {
  width: 10px;
  margin: 10px;
  height: 10px;
}

h1,
h2,
h3,
h4,
h5 {
  .ant-typography {
    margin: 0;
    padding: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;