@import "theme/shared.less";
@import "theme/table.less";

@disabledColor: rgba(0, 0, 0, 0.25);

.widget {
  margin: 8px;
  background-color: @minor-bg;
  border-radius: 8px;
  border: 1px solid @table-color-border;
  height: 100%;
  display: flex;
}

.widget > div:first-child {
  width: 100%;
}

.tableCol {
  text-align: end;

  input {
    text-align: end;
  }
}

.stratum {
  display: flex;
  justify-content: space-between;

  position: relative;
  width: 100%;
  height: 100%;
  div {
    width: 100%;
  }

  span {
    display: block;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

.select {
  display: flex;
  width: 100%;

  span {
    display: block;
  }
}

.dragger {
  width: 100%;
  height: 100%;
}

.notDataText {
  margin-top: 13px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(77, 87, 97, 1);
}

.gtmIcon {
  width: 36px;
  height: 42px;
  color: rgba(77, 87, 97, 1);
}

.uploadIcon {
  width: 16px;
  height: 16px;
  color: white;
}
.addBtn {
  border: none;
  box-shadow: none;

  &:focus-visible {
    outline: none;
  }
}

.divider {
  height: 24px;
  display: flex;
  margin: auto 5px;
  width: 1px;
  background: rgba(229, 231, 235, 1);
}

.tableHeader {
  background: #f7f7f7;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-wrap: wrap;
}

.importIcon {
  width: 16px;
  height: 16px;
}

.indexList {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stratumSelect {
  width: 100%;
  padding: 0;
  :global {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.isNotIncomplete {
  background: rgba(247, 232, 234, 1);
  width: 100%;
}

.lazyInput {
  font-size: 14px;
}

.remove {
  position: sticky;
  right: 0;
}

.disabled {
  color: @disabledColor;
  transition: all 0.5s;

  :global(.ant-select-selection-item) {
    color: @disabledColor;
    cursor: not-allowed;
  }
}

.tableCell {
  font-size: 12px;
}

.selector {
  :global(.ant-select-selector) {
    font-size: 12px;

    input {
      font-size: 12px;
    }
  }
}

.date {
  :global {
    .ant-picker {
      width: 100%;
    }
    .ant-picker-input > input {
      font-size: 12px;
      width: 100%;
    }
  }
}

.fallBackBg {
  background-color: #ffbac1 !important;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;