.debugColumnCell {
  padding: 0;

  > span {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cell {
  padding: 2px 8px !important;
  text-align: center;
  justify-content: center;
  background: repeating-linear-gradient(135deg, #fff1b8 0, #fff1b8 5px, #fffbe6 5px, #fffbe6 10px) !important;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;