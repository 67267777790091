@import "theme/table.less";

.root {
  display: flex;
  height: 100%;

  & > * {
    width: 100%;
  }

  .tableWrap {
    width: 100%;
    height: 100%;
    .table {
      :global(.ant-picker) {
        padding: 0;
      }
      .prodRow {
        background-color: #e3fbcc;
      }

      .injRow {
        background-color: #cff9fe;
      }

      .invalidRow {
        background-color: #f7e8ea;

        > .invalidCell {
          background-color: #ffbac1;
        }
      }

      .warn {
        font-size: 16px;
        color: #faad14;
      }
    }
  }

}
.groupRowsBtn {
  order: -1;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;