@import "theme/shared.less";

.wrapper {
  position: relative;
  flex-grow: 1;
  background-color: #fff;
  border: 1px solid @paddings-color;
  margin-bottom: 8px;
  padding: 8px;
}

.chart {
 height: 100%;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;