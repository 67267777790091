@import "theme/shared.less";

.header {
  display: flex;
  gap: 1px;
  padding-right: 1px;
  flex-direction: column;

  .tophead {
    flex: 1;
    align-items: center;
  }
  .bottomhead {
    :global {
      .ant-menu-submenu-popup {
        width: 100%;
        max-width: 220px;
      }
    }
  }

  .bottomhead,
  .tophead {
    background-color: white;
    :global(.anticon) {
      font-size: calc(24px / 1.1428);
    }

    :global {
      .ant-menu-item-active,
      .ant-menu-submenu-title:hover {
        .anticon {
          color: @primary-color;
        }
      }
    }
  }

  .bottomhead {
    :global {
      .ant-menu-submenu-arrow,
      .ant-menu-title-content {
        display: none;
      }
      .ant-menu-root {
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
        min-width: @menu-side-width;

        .ant-menu-item-selected,
        .ant-menu-item:hover,
        .ant-menu-submenu:hover,
        .ant-menu-submenu-title:hover {
          background: none;
        }

        .ant-menu-item {
          @import "./modeSwitch/notch.less";
        }

        .ant-menu-item,
        .ant-menu-submenu {
          padding: 0;
          text-align: center;
          max-width: @menu-side-width;
        }
        .ant-menu-submenu-title {
          overflow: visible;
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
    }

    li :global(.ant-btn) {
      border: none;
      box-shadow: none;
      padding: 0;

      svg {
        fill: currentColor;
      }
    }
  }
}

:global {
  .ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item {
    height: 30px;
    line-height: 30px;
  }
}


@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;