@import "theme/shared.less";
@import "theme/table.less";

@warning: rgba(247, 232, 234, 1);

.catalogType {
  width: 100%;
  :global {
    .ant-select-selection-item,
    .ant-select-selector {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.date {
  width: 100%;
  :global {
    .ant-picker {
      width: 100%;
    }
    .ant-picker-input > input {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.remove {
  position: sticky;
  right: 0;
}

.actionButtons {
  display: flex;
  gap: 8px;

  .regime {
    display: flex;
  }

  :global(.ant-select) {
    min-width: 180px;
  }

  button {
    min-width: 32px;
  }
}

.noPadding {
  padding: 0 !important;
}

.title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.warning {
  &:hover {
    background: @warning !important;
    border-color: rgba(255, 186, 193, 1) !important;
  }
}

.warningProblem {
  background: @warning;
  border-color: rgba(255, 186, 193, 1);
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;