.container {
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid #E5E7EB;
}

.user-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #E5E7EB;

  .info {
    display: flex;

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      background-color: #DDD;
      margin-right: 8px;
    }

    .name {
      width: 200px;
      align-self: center;
    }

    .email {
      width: 250px;
      align-self: center;
    }
  }

  .right {
    display: flex;

    .role {
      width: 284px;
      align-self: center;
    }

    .role-select {
      width: 100%;
    }

    .delete {
      width: 32px;
      padding-left: 16px;
    }

    .delete-button {
      width: 32px;
      height: 32px;
    }
  }
}

.select {
  width: 288px;
  margin-bottom: 12px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;