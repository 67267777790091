.tableHeader > div {
  background-color: #f7f7f7;
  font-weight: 600;
  font-size: 14px;
  text-wrap: wrap;
}
.tableRowPrimary > div {
  background-color: #d1e9ff;
  font-weight: 600;
}
.tableRowSecondary > div {
  background-color: #eff8ff;
}
.tableRowPlain > div {
  background-color: white;
}
.tableCellAlignRight {
  justify-content: end;
  text-align: end;
}
.tableCellLock {
  background-color: #e5e7eb;
}
