@import "theme/shared.less";

.placeholder {
  background: white;
  border-radius: 10px;
  border: 1px solid @table-color-border;
}

.main {
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;

  .sectionTop {
    display: flex;
    height: 47%;
    gap: 8px;

    .settings {
      width: 30%;
      overflow-y: auto;
      padding: 8px;

      .placeholder();
    }

    .table {
      width: 70%;
      .placeholder();
    }
  }

  .chart {
    flex: 1;
    display: flex;
    width: 100%;
    .placeholder();
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;