.container {
  position: relative;

  .arc {
    position: relative;
  }

  .middleText {
    display: flex;
  }
}
.middleText {
  padding: auto;
}

.center-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100px;
}

.donutTooltip {
  background-color: rgba(77, 87, 97, 1);
  z-index: 1001;
  border-radius: 8px;
  padding: 8px 12px;
  max-width: 190px;
  opacity: 0;
  overflow: hidden;
  display: none;
  font-family: "Inter";
}

.filter {
  position: relative;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;