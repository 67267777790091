
:global(.forecast) {
  stroke-dasharray: 10,5,2,2,2,5;
}

:global(.fitting) {
  path {
    stroke-width: 3px;
    stroke-dasharray: 10, 5;
  }
}
