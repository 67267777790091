.card {
  min-height: 395px;
  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex: 1;

    .inLine {
      display: flex;
      gap: 8px;
    }
    .big {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    .pieCenter {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 50px;

      > span {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      > * {
        flex: 1
      }
    }
  }
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;