.container {
  position: absolute;
  z-index: 1000000;
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: rgba(77, 87, 97, 1);
  border-radius: 8px;
  padding: 8px 12px;
  width: 160px;
  z-index: 1000000;
  position: absolute;
  animation: fade 0.2s linear;
  overflow: auto;
  max-height: 250px;

  &::-webkit-scrollbar-track-piece {
    background-color: rgb(60, 67, 75);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgba(77, 87, 97, 1);
    border: 2px solid rgb(60, 67, 75);
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
}

.header {
  color: rgba(210, 214, 219, 1);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.valueRow {
  display: flex;
  align-items: center;
  column-gap: 4px;
  .circle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
}

.value {
  color: #fff;
  font-size: 10px;
}

.year {
  color: #fff;
  font-size: 10px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;