.status-card {
  margin-top: 8px;

  .info-container {
    padding: 8px;
    background-color: #F3F4F6;
    border-radius: 4px;
    min-height: 32px;
    position: relative;

    .action-button {
      font-size: 12px;
    }

    .button-container {
      margin-top: 8px;
      display: flex;
      align-items: center;
    }
  }

  .status-position {
    position: absolute;
    right: 8px;
    top: 16px
  }

  .info {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.status {
  display: flex;
  align-items: center;

  .status-text {
    margin-right: 4px;
    line-height: 16px;
  }
}

.in-progress {
  color: #F79009;
}

.on-approval {
  color: #1570EF;
}

.approved {
  color: #4CA30D;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;