@import "theme/table.less";

.rowBorderNone > div {
  border-right: none !important;
  border-left: none !important;
}

.tableFirstCell {
  padding: 2px 8px !important;
  font-size: 12px;
  div, input {
    font-size: 12px;
  }
}

.tableCell {
  font-size: 12px;
  font-weight: 400 !important;;
  div {
    font-size: 12px !important;
  }
  input, :global(.ant-picker) {
    width: 100%;
    font-size: 12px !important;
  }
}

.boundaryConditionsCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px 0 2px 8px;

  :global(.anticon) {
    cursor: pointer;
  }
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;