@import "theme/shared.less";

.playerContainer {
  background-color: white;

  display: flex;
  justify-content: space-between;
  padding: 16px;


  border: 1px solid @paddings-color;

  .grey {
    color: rgba(157, 164, 174, 1);
  }

  .btnGroup {
    display: flex;
    gap: 16px;

    :global {
      .ant-select .ant-select-selector {
        background: linear-gradient(rgba(209, 233, 255, 1) 0 0) 0 /
          var(--progress) no-repeat white !important;
        transition: background var(--animation-tick) linear !important;
      }
    }

    .switch {
      display: flex;
      gap: 8px;
    }
    .timerGroup {
      display: flex;
      gap: 8px;
      overflow: hidden;

      .timeOut {
        width: 124px;

        .selectLabel {
          display: flex;
          align-items: center;
          gap: 6px;
          z-index: 2;
          position: relative;

          .text {
            display: flex;
            gap: 4px;
          }
        }
      }
    }
  }
}
.progressBar {
  position: relative;
  .progress {
    height: 2px;
    width: 100%;
    max-width: 100%;
    transition-duration: 0.2s;
    position: absolute;
  }
  .circle {
    background-color: @colorPrimary;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    top: -6px;
    border: 2px solid white;
    position: absolute;
    transition-duration: 0.2s;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;