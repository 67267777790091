.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  border: 1px solid silver;
  border-radius: 5px;

  padding: 5px 10px;

  font-size: 15px;
  background: white;
  width: 700px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}

.filterListStatic {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filterList {
  display: grid;
  grid-template-columns: 1fr 194px 1fr 32px 32px;
  width: 100%;
  gap: 5px;
  max-height: 300px;
  overflow: auto;

  align-items: center;

  p {
    padding: 0;
    margin: 0;

    &:nth-child(3) {
      grid-column-start: 3;
      grid-column-end: 6;
    }
  }
}

.buttons {
  display: flex;
  gap: 5px;
  min-width: 70px;
}

.radioGroup {
  display: flex;
  flex-direction: row;

  > label {
    display: flex;
    padding-inline: 0;
    justify-content: center;
    width: 100%;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;