.buttons {
  display: flex;
  justify-content: space-between;
}

.amount {
  color: #9DA4AE;
  font-size: 10px;
}

.grid {
  display: grid;
  grid-template-columns: auto 100px 100px;
  gap: 8px;
  align-items: center;
  &>span {
    line-height: 10px;
  }

  .durationTitle {
    grid-column-end: 3;
    grid-column-start: 1;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;