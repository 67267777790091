@import "theme/table.less";

.tooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.root {
  display: flex;
  height: 100%;

  .leftPane {
    border-right: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
  }

  .tree {
    width: 316px;
    border-bottom: 1px solid #d9d9d9;
  }

  .table {
    width: 100%;

    .prodRow {
      background-color: #e3fbcc;
    }

    .injRow {
      background-color: #cff9fe;
    }

    .invalidRow {
      background-color: #f7e8ea;

      > .invalidCell {
        background-color: #ffbac1;
      }
    }
  }
}

.dateColumn {
  :global(.ant-picker) :global(.ant-picker-input) > input:disabled {
    color: black;
  }
}

.favorite {
  background-color: #e5e7eb !important;
}

.headerCell {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
}

.cell {
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: right;
  width: "100%";
}

.profit {
  background-color: rgba(166, 239, 103, 1);
  border-radius: 5px;
  padding: 2px 4px;
}

.nonprofit {
  background-color: rgba(255, 186, 193, 1);
  border-radius: 5px;
  padding: 2px 4px;
}


.favHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;