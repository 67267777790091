@import "theme/shared.less";

& {
  user-select: none;
  cursor: auto;

  &:hover {
    background-color: @hover-color-bg-opacity
  }
}
