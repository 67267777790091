.body {
  background-color: white;
  padding: 16px;
  margin: 1px 1px 0 0;
  overflow-y: auto;

  .pumping {
    display: flex;
    gap: 8px;
    align-items: center;

    > :first-child {
      flex: 1;
    }
  }

  .title {
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .row {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.inputNumber {
  width: unset;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;