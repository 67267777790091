.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: white;

  .list-skeleton {
    padding-left: 18px;
    padding-right: 18px;
  }

  .search {
    margin-bottom: 16px;
    margin-inline: 4px;
    width: calc(100% - 8px);
  }

  .list {
    height: 100%;

    .treeRow {
      height: 30px;
      display: inline-flex;
      align-items: baseline;
      gap: 10px;
    }

    .node {
      font-weight: 600;
    }

    .leaf {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: white;

    > span {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;