@import "theme/shared.less";

header {
  height: @header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
  background-color: @table-color-cell-lock;

  .buttons {
    display: flex;
    gap: 16px;

    .coloredButton {
      color: "#2e90fa";
    }

    @media (max-width: 1350px) {
      > :last-child {
        display: none;
      }
    }

    :global(.ant-btn) {
      background-color: @table-color-cell-lock;
      border-width: 2px;

      &:hover {
        background-color: white;
      }
    }

    :global(.anticon) {
      font-size: 16px;
    }
  }

  .logo {
    position: absolute;
    left: 13px;
    top: 5px;
  }
}

.content {
  max-height: calc(100vh - @header-height);
  height: calc(100vh - @header-height);
  display: flex;

  > * {
    flex: 1;
  }

  > :nth-child(n + 2) {
    flex: 1000;
  }

  .layout {
    display: flex;
    width: calc(100vw - @menu-side-width - 1px);

    > :last-child {
      flex: 1;
    }
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;