@import "theme/shared.less";
@import "../InfrastructureMap.module.less";

@title-color: #6c737f;
@white-color: #FFF;


.menu {
  height: 100%;
  min-width: 383px;
  max-width: 383px;
  background-color: @white-color;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001a;
  z-index: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  border-radius: 4px;
  background-color: @white-color;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  line-height: 48px;
  height: 48px;
  padding: 0px 16px;
  margin: 0px 1px 0px 0px;
  align-items: center;
  border-bottom: 1px solid @paddings-color;
  justify-content: space-between;
  text-transform: uppercase;

  :global(.anticon) {
    font-size: 24px;
    cursor: pointer;
  }
}

.container {
  padding: 16px 0px 16px 16px;
  flex: 1;
  height: 100%;

  form {
    height: 100%;
    overflow: hidden;
    padding-right: 14px;

    :global {
      .ant-tabs {
        padding-right: 0;
      }
    }
  }

  :global {
    .ant-tabs {
      padding-right: 14px;
    }
    .ant-tabs-content, .ant-tabs-tabpane {
      height: 100%;
    }
    .ant-form-item-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .ant-form-item-label {
        display: flex;
        text-align: start;
        text-wrap: wrap;
      }
    }
  }
}

.param {
  display: flex;
  column-gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.tooltipParam {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 12px;
}

.cardTitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: @title-color;
  margin-bottom: 12px;
  align-items: center;
  display: flex;
  column-gap: 4px;
  text-transform: uppercase;
}

.cardWrapper {
  cursor: pointer;
  width: 168px;
  min-height: 108px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid @paddings-color;
  box-sizing: border-box;

  &:hover {
    border: 1px solid @primary-color;
    background: @table-color-secondary;

    :global(.ant-typography) {
      color: @primary-color;
    }

    svg {
      path, rect {
        fill: @primary-color;
      }
    }
  }
}

.cardWrapperFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: max-content;
  align-items: center;
  margin-bottom: 12px;

  .cardTitle {
    margin-bottom: 0;
  }
}

.categoryTitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: @title-color;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.categoryList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
}

.fieldParams {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  div:first-child {
    color: @link-color;
    text-wrap: wrap;
    align-items: center;
    display: flex;
  }
  div:last-child {
    color: #111927;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.emptyBlock {
  height: 100%;
  min-height: 226px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  text-align: center;
  background: rgba(243, 244, 246, 1);
  padding: 0 16px;
}

.label {
  color: @title-color;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.coordParamsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;