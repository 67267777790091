@import "theme/shared.less";
@import "theme/table.less";

.rowBorderNone > div {
  border-right: none !important;
  border-left: none !important;
}

.kindPopup {
  :global {
    .ant-select-item-option {
      align-items: center;
    }
    .ant-select-item {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.isReconstruction {
  width: 50px;
  font-size: 8px;

  :global {
    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      font-size: 8px;
      font-weight: 400px;
      line-height: 12px;
    }
    .ant-switch-inner-checked {
      margin-top: 4px;
    }
    .ant-switch-inner > .ant-switch-inner-unchecked {
      margin-top: -12px;
    }
  }
}

.tableFirstCell {
  padding: 2px 8px !important;
  font-size: 12px;
  div,
  input {
    font-size: 12px;
  }
}

.tableCell {
  font-size: 12px;
  div {
    font-size: 12px !important;
  }
  input {
    font-size: 12px !important;
    text-align: end;
  }
}

.date {
  input {
    text-align: end;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;