@import "theme/shared.less";

.infrastructureWrap {
  display: flex;
  column-gap: 8px;
  width: 100%;
  height: 100%;
  border: none !important;
}

.layout {
  background-color: @paddings-color;
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid @paddings-color;
  border-radius: 4px;
  outline: none;

  .table,
  .map,
  .datePane {
    background-color: white;
    border-radius: 4px;
  }

  .mapNDate {
    display: flex;
    flex-direction: column;
    gap: 1px;
    height: 100%;

    .map {
      flex: 1;

      svg {
        width: 100%;
        height: 100%;
        user-select: none;
      }
    }

    .cursorNone {
      cursor: none;
    }
  }
}

.selection {
  fill: #2e90fa1a;
  stroke: @colorPrimary;
}

.cursorSelection {
  cursor: crosshair;

  & > div {
    pointer-events: initial;
  }
}

.dragging {
  cursor: grabbing;
}

.selectedItem {
  cursor: default;
}

.legendMap {
  width: 233px;
  border-radius: 4px;
  border: 1px solid @table-color-head-lock;
  background: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: absolute;
  bottom: 50px;
  right: 10px;

  .legendFlex {
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .horizontalLine {
    width: 24px;
    height: 2px;
    background-color: @colorPrimary;
  }

  .dottedLine {
    border-top: 2px dotted @colorPrimary;
    width: 24px;
    height: 0px;
  }

  .colorBlock {
    flex: 1;
    height: 8px;
  }

  .scaleLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }
}

.text {
  fill: @icon-color;
  background: #ffffffcc;
  font-size: 12px;
}

.bgText {
  fill: #ffffffcc;
}

.importPopConfirm {
  max-width: 400px;
}