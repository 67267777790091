@import "theme/shared.less";

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 1px;

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .title {
    background-color: white;
    margin: 1px 0px 1px 0px;
    display: flex;
    height: 48px;
    align-items: center;
    border-bottom: 1px solid @table-color-border;
    margin-bottom: 4px;
    padding: 0 16px;
    gap: 8px;
    .legendItem {
      display: flex;
      align-items: center;
      gap: 4px;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }


  .chart {
    background-color: white;
    flex: 1;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;