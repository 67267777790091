.filter {
  display: flex;
  max-height: 450px;
  max-width: 80vw;
  flex-direction: column;
  overflow-x: auto;
  flex-wrap: wrap;
  gap: 10px;

  .group {
    display: flex;
    flex-direction: column;
  }

  .reset {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.button svg {
  font-size: 24px;
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;