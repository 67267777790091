.axis {
  pointer-events: none;
  user-select: none;
}

.grid {
  stroke: #dddddd;

  .y {
    stroke-dasharray: 0 4 0;
  }
}

.tooltip {
  display: none;
  flex-direction: column;
  gap: 4px;
  background-color: rgba(77, 87, 97, 1);
  border-radius: 8px;
  padding: 8px 12px;
  z-index: 1000000;
  position: absolute;
  color: rgba(210, 214, 219, 1);
  pointer-events: none;

  &:empty {
    display: none !important;
  }
}

@transitionDuration: 200;@table-first-column-width: 80;@table-second-column-width: 380;@table-other-column-width: 130;@table-child-padding-addition: 16;@table-color-head-lock: rgba(229, 231, 235, 1);@table-color-cell-lock: #F3F4F6;@defaultColor: #808080;@axisWidth: 65;@colorPrimary: #2e90fa;@icon-color: #4d5761;@borderColor: #d9d9d9;